.mainContainer {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainContainer img {
  object-fit: cover;
  padding-bottom: 0.5rem;
  max-width: 80rem;
  width: 100%;
}

.slightlyScale {
  transform: scale(1.001);
  z-index: -1;
}

.mainContainer video {
  object-fit: cover;
  padding-bottom: 0.5rem;
  max-width: 80rem;
  width: 100%;
}

.mainContainer .main {
  width: 100vw;
  max-width: 100vw;
}

.padding {
  max-width: 80rem;
  width: 100%;
  height: 8rem;
  background-color: #121212;
}

.grid {
  max-width: 80rem;
  height: auto;
  justify-content: space-between;
}

.grid video {
  height: auto;
  width: calc(38% - 0.25rem);
}

.grid div {
  width: calc(62% - 0.25rem);
  justify-content: space-between;
}

.grid > div > img {
  height: auto;
}

.grid2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 80rem;
  width: 100%;
}

.grid2 > div {
  width: calc(62% - 0.25rem);
  justify-content: space-between;
}

.grid2 > img {
  width: calc(38% - 0.25rem);
  object-fit: cover;
}

.grid2 > div > img {
  object-fit: cover;
}

.nextProj {
  text-align: center;
  color: black;
  display: inline-block;
  margin: 2rem;
  opacity: 0.5;
  font-family: "SatoshiRegular" !important;
  font-size: 600;
  letter-spacing: -0.02rem;
}

.nextProj:hover {
  opacity: 1;
}
