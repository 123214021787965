.backPadding {
  background-color: rgba(0, 0, 0, 0.723);
  width: 100vw;
  height: 7.5rem;
  position: fixed;
  transition: 0.3s;
}

.detailsLink p {
  position: fixed;
  transition: 0.3s;
  cursor: pointer;
  max-width: calc(80rem - 2rem);
  width: 100%;
  left: calc(50% - 1rem);
  margin: 5rem 0 0 2rem;
  transform: translate(-50%, 0);
}

.detailsLink p:hover {
  text-decoration: underline;
}

.mainDetails {
  top: 300%;
  background: rgba(0, 0, 0, 0.946);
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  padding-top: 7rem;
}

.mainDetails svg {
  opacity: 0.5;
  cursor: pointer;
  text-align: left;
}

.mainDetails svg:hover {
  opacity: 1;
}

.svgContainer {
  max-width: 75rem;
  width: calc(100% - 4rem);
  margin: 0 auto;
  padding: 0 2rem;
}

.active {
  top: 0;
}

.mainDetails p {
  color: white;
  max-width: 75rem;
  width: calc(100% - 4rem);
  font-family: "GeneralSansBold" !important;
  margin-bottom: 1rem;
  margin: auto;
  padding: 0 2rem;
}

.mainDetails h1 {
  color: white;
  max-width: 75rem;
  width: calc(100% - 4rem);
  font-family: "SatoshiRegular" !important;
  letter-spacing: -0.07rem;
  margin-bottom: 0.5rem;
  margin: auto;
  padding: 0 2rem;
}
