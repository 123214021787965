.loadingContainer {
  background-color: #000000;
  height: 100%;
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 9;
  transition: 0.1s;
  transition-timing-function: linear;
}

.svg-defs {
  height: 20px;
  left: -100px;
  position: absolute;
  top: -100px;
}

.loaded {
  top: -150% !important;
  transition: 1s;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
}

.loading {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading__filler {
  //   transform: skew(0, -10deg);
}
.loading__logo {
  clip-path: url(#pictogram);
  height: auto;
  width: auto;
  transform: scale(2);
}
