@font-face {
  font-family: "GeneralSansBold"; /*Can be any text*/
  src: local("GeneralSansBold"),
    url("./fonts/GeneralSans-Regular.otf") format("opentype");
}

@font-face {
  font-family: "SatoshiRegular"; /*Can be any text*/
  src: local("SatoshiRegular"),
    url("./fonts/Satoshi-Medium.otf") format("opentype");
}

@font-face {
  font-family: "SatoshiSmall"; /*Can be any text*/
  src: local("SatoshiSmall"),
    url("./fonts/Satoshi-Regular.otf") format("opentype");
}

* {
  margin: 0;
  font-family: "GeneralSansBold";
}

html {
  font-size: 16px;
}
body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

input,
textarea {
  -webkit-appearance: none;
  border-radius: 0;
}

img,
video {
  pointer-events: none;
}
