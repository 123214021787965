.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.onlyMobile {
  display: none;
}

.onlyPC {
  display: block;
}

@media screen and (max-width: 768px) {
  .onlyMobile {
    display: block;
  }

  .onlyPC {
    display: none;
  }
}
