.navbarContainer {
  position: fixed;
  /* width: calc(100vw - 4rem); */

  width: calc(100% - 4rem);
  max-width: 75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navHolder {
  position: fixed;
  width: calc(100vw - 4rem);

  display: flex;
  justify-content: center;
  z-index: 1;
  padding: 2rem 2rem 1rem 2rem;
  height: 2rem;
  transition: 0.3s;
}

.blacked {
  padding: 1rem 2rem 1rem 2rem;
  background-color: black;
  transition: 0.3s;
}

.blacked p {
  color: white !important;
}
.blacked svg {
  fill: white !important;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
}

.logo p {
  margin-left: 10px;
}

.links {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.links p {
  margin-left: 3rem;
  font-size: 1rem;
  opacity: 0.4;
  transition: 0.3s;
  cursor: pointer;
}

.active {
  opacity: 1 !important;
}

.links p:hover {
  opacity: 1;
}

.hamburger {
  display: none;
}

.mobileNav {
  display: flex;
  position: fixed;
  background-color: black;
  height: 100vh;
  width: 100vw;
  color: white;
  flex-direction: column;
  justify-content: center;
  font-size: 2rem;
  text-align: center;
  top: -150%;
  transition: 0.5s;
  z-index: 10;
}

.mobileNav p {
  margin: 3rem 0;
  opacity: 0.4;
  cursor: pointer;
  transition: 0.3s;
}

.mobileNav p:hover {
  opacity: 1;
}

.mobileNav svg {
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.navOpen {
  top: 0;
}

@media screen and (max-width: 768px) {
  .navbarContainer {
    width: calc(100vw - 3rem);
    padding: 0;
  }

  .navHolder {
    padding: 1.5rem 0.4rem 1rem 0.4rem;
    width: calc(100vw - 0.8rem);
  }

  .blacked {
    padding: 1rem 0.4rem 1rem 0.4rem;
    background-color: black;
    transition: 0.3s;
  }

  .links {
    display: none;
  }

  .hamburger {
    display: flex;
    align-items: center;
  }

  .mobileNav p {
    margin: 1.5rem 0;
  }

  .mobileNav svg {
    top: 1.5rem;
    right: 1.5rem;
  }
}
