.previewContainer {
  margin: 0.15rem 0.3rem;
  transition: 0.3s;
  cursor: pointer;
}

.previewContainer:hover {
  transform: translate(0, -1rem);
}

.previewContainer img {
  object-fit: cover;
}

.previewContainer video {
  position: absolute;
  object-fit: cover;
}

.details {
  opacity: 0;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  transition: 0.5s;
}

.details p {
  color: white;
  font-size: 1.7rem;
  margin-bottom: 0.8rem;
}
.details span {
  color: white;
  font-size: 0.8rem;
}

.invisible {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .previewContainer img {
    width: calc(100vw - 2rem) !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1245px) {
  .previewContainer img {
    width: calc(100vw - 2rem) !important;
  }
}
