/* The container must be positioned relative: */
.custom-select {
  position: relative;
  margin-top: 0;
}

.custom-select select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  background: none;
  width: 9rem;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #000000 transparent;
  top: 25px;
  transform: rotate(180deg);
}

/* style the items (options), including the selected item: */
.select-selected {
  color: grey;
  margin-left: 0.7rem;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.6) transparent;
  cursor: pointer;
}

.select-items div {
  color: grey;
  background-color: white;
  padding: 8px 2rem;
  border: none;
  /* border-color: transparent transparent rgba(0, 0, 0, 0.6) transparent; */
  cursor: pointer;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: rgb(255, 255, 255);
  flex-direction: column !important;
  top: 100%;
  padding: 0 !important;
  left: 0;
  right: 0;
  z-index: 99;
  width: 9rem;
  margin-left: 0.7rem;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgb(219, 219, 219);
  color: black !important;
}

@media screen and (max-width: 859px) {
  .select-selected {
    border-color: rgba(0, 0, 0, 0.6);
    margin-left: 0;
  }

  .select-items {
    margin: 0;
  }

  .select-items div {
    width: calc(100vw - 10.8rem);
  }
}
