.homeContainer {
  height: calc(100vh - 10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.homeContainer img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.homeContainer video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-color: black;
}

.homeContainer p {
  font-family: "SatoshiRegular" !important;
  letter-spacing: -0.25rem;
  position: absolute;
  font-size: 5rem;
  color: white;
}

.projectsContainer {
  min-height: 20rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.projectsContainer p {
  color: black;
  text-align: center;
  font-family: "SatoshiRegular" !important;

  font-size: 1.6rem;
}

.previews {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottomHome {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem;
}

.bottomHome p {
  font-family: "SatoshiRegular" !important;
  margin-bottom: 2rem;
  font-size: 1.6rem;
}

.bottomHome button {
  background-color: black;
  border: none;
  color: white;
  width: 8rem;
  height: 2.5rem;
  font-family: "SatoshiSmall" !important;
  cursor: pointer;
  transition: 0.3s;
}

@media screen and (max-width: 768px) {
  .homeContainer p {
    font-size: 9vw;
    letter-spacing: -0.1rem;
  }

  .previews > div {
    flex-direction: column !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1245px) {
  .previews > div {
    flex-direction: column !important;
  }
}
