.footerContainer {
  padding: 2rem;
  outline: 1px solid rgb(175, 175, 175);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
  opacity: 0.5;
  width: 15rem;
}

.logo p {
  margin-left: 10px;
}

.links {
  display: flex;
  flex-direction: row;
  width: 19rem;
}

.links p {
  margin-left: 1rem;
  font-size: 0.9rem;
  color: grey;
  text-decoration: underline;
  cursor: pointer;
}

.tyger {
  font-size: 0.9rem;
  color: grey;
}

.tyger span {
  display: inline;
}

@media screen and (max-width: 768px) {
  .logo {
    display: none;
  }

  .links {
    padding-right: 0;
    justify-content: center;
  }

  .tyger {
    margin-bottom: 1rem;
  }

  .tyger span {
    display: none;
  }

  .footerContainer {
    flex-direction: column;
  }

  .links p {
    margin: 0.4rem 0.4rem 0.4rem 0.4rem;
  }
}
