.mainContainer {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainContainer img {
  object-fit: cover;
  padding-bottom: 0.5rem;
  max-width: 80rem;
  width: 100%;
}

.mainContainer .main {
  width: 100vw;
  max-width: 100vw;
}

.padding {
  max-width: 80rem;
  width: 100%;
  height: 8rem;
  background-color: #000000;
}
.nextProj {
  text-align: center;
  color: black;
  display: inline-block;
  margin: 2rem;
  opacity: 0.5;
  font-family: "SatoshiRegular" !important;
  font-size: 600;
  letter-spacing: -0.02rem;
}

.nextProj:hover {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .padding {
    height: 8rem;
  }
}
