.hero {
  height: 100vh;
}

.hero img {
  width: 35rem;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
}

.textContainer {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 57.5%;
  display: flex;
  flex-direction: column;
}

.hero p {
  font-family: "SatoshiRegular" !important;
  /* position: absolute; */
  font-size: 6.3rem;
  letter-spacing: -0.23rem;
  text-align: center;
  /* left: 50%;
  top: 57.5%; */
  /* transform: translate(-50%, 0); */
  background: linear-gradient(180deg, #0d0d0d 0%, #747474 79.07%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero span {
  /* position: absolute; */
  text-align: center;
  /* left: 50%;
  top: 73%; */
  font-size: 1.3rem;
  color: grey;
  /* transform: translate(-50%, 0); */
  width: 45rem;
}

.list {
  padding: 8rem 0;
  width: 100%;

  /* width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.list div {
  display: flex;
  justify-content: center;
  width: 20rem;
}

.list p {
  font-family: "SatoshiRegular" !important;
}

.subtitle {
  opacity: 0.5;
  width: 13rem;
}

.list svg {
  border: 1px solid rgb(199, 199, 199);
  border-radius: 30rem;
  padding: 1rem;
  width: 2rem;
  height: 2rem;
  fill: black;
  transition: 0.3s;
}
.left {
  display: flex !important;
  text-align: left !important;
  /* justify-content: left !important; */
}

.list svg:hover {
  fill: white;
  background-color: black;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
}

.form > p {
  font-family: "SatoshiRegular" !important;
  justify-content: center;
  font-size: 1.6rem;
  margin-bottom: 2rem;
}

.success {
  display: flex;
  padding: 7rem 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.05rem;
  text-align: center;
  font-family: "SatoshiRegular" !important;
}
.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.05rem;
  text-align: center;
  color: red;
  font-size: 1.3rem;
  font-family: "SatoshiRegular" !important;
}

.success p {
  font-weight: 600;
  font-family: "SatoshiRegular" !important;
  font-size: 2rem;
}

.success span {
  font-size: 1rem;
  font-family: "SatoshiRegular" !important;
  letter-spacing: -0.05rem;
  opacity: 0.5;
}

.innerBox {
  display: flex;
  flex-direction: column;
  background: #f7f7f7;
  width: 50rem;
  font-size: 1.1rem;
  padding: 2rem;
  position: relative;
  height: 20rem;
}

.innerBox div {
  font-family: "SatoshiSmall" !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 6rem;
  padding: 0.5rem 0;
}

.form .input {
  border: none;
  border-bottom: 1px dashed grey;
  background: none;
  font-size: 1.1rem;
  padding: 0 1rem;
  width: 8rem;
}

.form .input:focus {
  outline: none;
  border-bottom: 3px solid black;
}

.form button {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 10rem;
  height: 2.5rem;
  border: 1px solid black;
  background: none;
  transition: 0.3s;
  cursor: pointer;
  color: black;
}

.form button:hover {
  background-color: black;
  color: white;
}

@media screen and (max-width: 859px) {
  .hero img {
    height: auto;
    width: calc(100vw - 5rem);
  }

  .textContainer {
    top: calc(42.5% + 24vw);
  }

  .hero p {
    font-size: 15vw;
    text-align: center;
    width: calc(100vw - 2rem);
    top: calc(42.5% + 20vw);
  }

  .hero span {
    width: calc(100vw - 2rem);
    top: calc(40.5% + 48vw);
    font-size: 1.1rem;
  }

  .list {
    flex-direction: column;
    align-items: center;
    /* width: calc(100vw - 1.29rem); */
    width: 100%;
  }

  .list > div {
    /* width: calc(100vw - 2rem); */
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    padding: 2rem 0;
  }

  .left {
    align-items: center;
    justify-content: center;
    text-align: center !important;
  }

  .list svg {
    margin: 2rem;
  }

  .list {
    padding: 3rem 0;
  }
  .innerBox {
    align-items: center;
    width: calc(100vw - 5.3rem);
  }
  .innerBox div {
    flex-direction: column;
    align-items: center;
    width: calc(100vw - 7rem);
    text-align: center;
  }

  .form .input {
    width: calc(100vw - 7rem);
    margin-bottom: 1rem;
    border: 1px solid grey;
    padding: 0.5rem 0;
    text-align: center;
  }

  .form .input:focus {
    border: 1px solid black;
  }

  .form button {
    position: static;
    margin-top: 2rem;
    width: calc(100vw - 7rem);
    background-color: black;
    color: white;
  }

  .innerBox {
    height: auto;
  }
}

@media screen and (min-width: 815px) and (max-width: 1245px) {
  .hero img {
    height: auto;
    width: 28rem;
  }

  .hero p {
    font-size: 4.8rem;
    top: 53%;
  }

  .hero span {
    top: 65%;
    width: 45rem;
    /* font-size: 1rem; */
  }
}
