.playgroundContainer {
  height: 100vh;
  width: 100%;
}

.hero {
  height: 100vh;
}

.hero img {
  width: 35rem;
  position: absolute;
  left: 50%;
  top: 46.5%;
  transform: translate(-50%, -50%);
}

.textContainer {
  position: absolute;
  left: 50%;

  transform: translate(-50%, 0);
  top: 57.5%;
  display: flex;
  flex-direction: column;
}

.hero p {
  font-family: "SatoshiRegular" !important;
  /* position: absolute; */
  font-size: 5.5rem;
  letter-spacing: -0.23rem;
  /* left: 50%;
  top: 57.5%; */
  text-align: center;
  /* transform: translate(-50%, 0); */
  background: linear-gradient(180deg, #0d0d0d 0%, #747474 79.07%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero span {
  /* position: absolute; */
  text-align: center;
  /* left: 50%; */
  /* top: 72%; */
  font-size: 1.2rem;
  color: grey;
  /* transform: translate(-50%, 0); */
  width: 45rem;
}

@media screen and (max-width: 814px) {
  .hero img {
    height: auto;
    width: calc(100vw - 5rem);
    max-width: 35rem;
  }

  .textContainer {
    top: calc(42.5% + 24vw);
  }

  .hero p {
    font-size: 15vw;
    text-align: center;
    width: calc(100vw - 2rem);
    /* top: calc(42.5% + 20vw); */
  }

  .hero span {
    width: calc(100vw - 2rem);
    /* top: calc(40.5% + 48vw); */
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 815px) and (max-width: 1245px) {
  .hero img {
    height: auto;
    width: 28rem;
  }

  .hero p {
    font-size: 4.5rem;
    top: 55%;
  }

  .hero span {
    top: 65%;
    width: 45rem;
    /* font-size: 1rem; */
  }
}
